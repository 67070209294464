<template>
  <div
    v-if="subBanner && subBanner.data"
    class="sub-banner"
    :style="videoBackground"
  >
    <div
      class="sub-banner-media"
    >
      <div
        v-if="!subBanner.data.isVideo"
        class="sub-banner-picture"
      >
        <nuxt-img
          :src="subBanner.data.image.content"
          format="webp"
          width="520"
          height="520"
          sizes="sm:100vw md:50vw lg:400px"
          loading="lazy"
          alt="Background"
        />
      </div>
      <div
        v-else-if="subBanner.data.isVideo"
        class="sub-banner-video"
      >
        <ClientOnly>
          <vueVimeoPlayer
            v-if="vimeoPlayerOptions"
            v-bind="vimeoPlayerOptions"
            class="sub-banner-video__video"
          />
        </ClientOnly>
      </div>
      <div
        v-if="!subBanner.data.isVideo"
        class="sub-banner-background"
      >
        <nuxt-img
          :src="subBanner.data.image.background"
          format="webp"
          alt="Background"
          width="1500"
          height="1500"
          sizes="sm:100vw md:50vw lg:400px"
          loading="lazy"
        />
      </div>
    </div>
    <div
      class="sub-banner-content"
      :style="style"
    >
      <div
        class="sub-banner-content-column"
        :style="contentStyle"
      >
        <h2>{{ subBanner.data.heading }}</h2>
        <div class="sub-banner-detail">
          <p>{{ subBanner.data.description }}</p>
        </div>
        <div class="sub-banner-button">
          <nuxt-link :to="localePath(subBanner.data.link)">
            <span>{{ subBanner.data.button }}</span>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { ref, onMounted } from '@nuxtjs/composition-api';
import { contentGetters, useContent } from '@vue-storefront/gemini';
import { useWindow } from '~/composables';
import { useI18n } from '../helpers/hooks/usei18n';

export default {
  name: 'SubBanner',
  components: { vueVimeoPlayer },
  setup() {
    const { locale } = useI18n();
    const identifierHomePageSubBanner = `subBanner-${locale}`;
    const subBanner = ref({});
    const {
      blocks,
      // error,
      loadBlocks,
    } = useContent(`cmsBlockSubBanner${identifierHomePageSubBanner}`);

    const { isDesktopOrTablet } = useWindow();
    const vimeoPlayerOptions = ref(null);

    const getVimeoPlayerOptions = () => {
      vimeoPlayerOptions.value = {
        autoplay: true,
        controls: false,
        'player-height': isDesktopOrTablet.value ? (process.client ? `${window.innerWidth * (9 / 16)}px` : `${1366 * (9 / 16)}px`) : window.innerWidth * 1.77,
        'player-width': isDesktopOrTablet.value ? (process.client ? `${window.innerWidth > 1366 ? 1366 : window.innerWidth - 24}px` : '1366px') : window.innerWidth,
        loop: true,
        options: {
          muted: true,
          autopause: false,
        },
      };
    };

    onMounted(async () => {
      await loadBlocks({ identifiers: [identifierHomePageSubBanner] });
      subBanner.value = contentGetters.getCmsBlockContent(blocks.value)[identifierHomePageSubBanner];
      getVimeoPlayerOptions();
      const correctVideo = isDesktopOrTablet.value
        ? subBanner.value?.data?.video_url_desktop || null
        : subBanner.value?.data?.video_url_mobile || null;
      if (correctVideo) {
        vimeoPlayerOptions.value[
          correctVideo.includes('http') ? 'video-url' : 'video-id'
        ] = correctVideo;
      }
    });

    return {
      subBanner,
      blocks,
      vimeoPlayerOptions,
      loadBlocks,
    };
  },
  computed: {
    style() {
      return {
        backgroundColor: !this.subBanner.data.isVideo ? this.subBanner.data.background_color : 'transparent',
        flex: this.subBanner.data.isVideo ? '100%' : '58.35',
      };
    },
    contentStyle() {
      return {
        position: this.subBanner.data.isVideo ? 'absolute' : 'none',
        right: this.subBanner.data.isVideo ? 'auto' : 'unset',
        left: this.subBanner.data.isVideo ? 'auto' : 'unset',
        top: this.subBanner.data.isVideo ? '35%' : 'unset',

      };
    },
    videoBackground() {
      return {
        backgroundColor: this.subBanner.data.isVideo ? this.subBanner.data.background_color : 'transparent',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
body .sub-banner {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  position: relative;

  img {
      width: 100%;
      height: auto;
    }

  p {
    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }
  }

  .sub-banner-media {
    flex: 41.65%;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .sub-banner-background {
      height: 100%;

      img {
        height: 100%;
      }
    }

    .sub-banner-picture {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 82%;
    }
    .sub-banner-video {
      display: flex;
      justify-content: center;
      padding: 70px 0;
    }
    &__video{
      max-width: 100%;
      max-height: 100%;
    }
  }

  .sub-banner-content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    h2 {
      color: #141e16;
      font-family: 'Frank Ruhl Libre';
      font-size: 50px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 70px;
      margin-bottom: 12px;
      text-align: center;
    }

    .sub-banner-content-column {
      padding: 24px 12% 20px;

      .sub-banner-detail {
        margin-bottom: 60px;

        p {
          color: #141e16;
          font-family: 'Spartan';
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 23px;
          text-align: center;
        }
      }

      .sub-banner-button {
        display: flex;
        justify-content: center;
        a {
          background: #fff;
          min-width: 300px;
          text-align: center;
          transition-duration: 0.4s;

          &:hover,
          &:active,
          &:focus {
            background: #333;
          }

          span {
            color: #222a24;
            display: block;
            font-family: 'Spartan';
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 1px;
            line-height: 12px;
            padding: 28px 20px 24px;
            text-align: center;
            text-transform: uppercase;

            &:hover,
            &:active,
            &:focus {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  body .sub-banner {
    background: #fff;

    .sub-banner-picture {
      figure img {
        object-fit: contain;
      }
    }

    .sub-banner-content,
    .sub-banner-media {
      flex: 100%;
    }

    .sub-banner-content {
      h2 {
        font-size: 32px;
        line-height: 36px;
      }

      .sub-banner-content-column {
        padding: 26px 24px 28px;
        position: relative!important;
        top: unset!important;

        .sub-banner-detail {
          margin-bottom: 40px;

          p {
            font-size: 12px;
            line-height: 20px;
          }
        }

        .sub-banner-button {
          a {
            min-width: 100%;

            span {
              font-size: 10px;
              letter-spacing: 0;
              line-height: 10px;
              padding: 20px;
            }
          }
        }
      }
    }
  }
}
</style>

<style>
.sub-banner .sf-image {
  --image-width: 100%;
}

.sub-banner .sf-image--wrapper {
  display: flex;
  width: 100%;
}
</style>
